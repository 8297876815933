import background from './background.svg';
import brand from './kmf.svg';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';

import 'bootstrap/dist/css/bootstrap.min.css';
import './App.css';
import 'bootstrap-icons/font/bootstrap-icons.css';

function App() {
    return (
        <Container className="p-3">
            <img src={background} className="App-background" alt="Background" />
            <Row>
                <Col><img src={brand} className="App-brand" alt="KMF" /></Col>
            </Row>
            <Row>
                <Col>
                    <h1 className='text-light'>Kevin M. Finnigin</h1>
                </Col>
            </Row>
            <Row>
                <Col>
                    <a className='text-light m-2' href="https://www.linkedin.com/in/kevinfinnigin/" target="_blank" rel="noreferrer"><i className="bi bi-linkedin" style={{ 'font-size': '2rem', color: 'white' }}></i></a>
                    <a className='text-light m-2' href="https://www.github.com/kfinny" target="_blank" rel="noreferrer"><i className="bi bi-github" style={{ 'font-size': '2rem', color: 'white' }}></i></a>
                </Col>
            </Row>
            <Row>
                <Col>
                    
                </Col>
            </Row>
        </Container>
    );
}

export default App;
